<template>
  <div class="sample">
    <van-nav-bar
      title="报检填报"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <van-form @submit="onSubmit">
      <van-cell-group class="mt10">
        <van-field
          v-model="form.type"
          name="inspect_type"
          label="报检类型"
          placeholder="请选择报检类型"
          readonly
          required
          :rules="[{ required: true, message: '请选择报检类型' }]"
          @click="typeStatus = true"
        />
      </van-cell-group>
      <van-cell-group class="mt10">
        <van-field
          v-model="form.inspect_emp_name"
          name="inspect_dep_name"
          label="报检员"
          disabled
          required
        />
        <van-field
          v-model="form.inspect_dep_name"
          name="inspect_emp_name"
          label="报检部门"
          disabled
          required
        />
        <van-field clearable
          v-model="form.contract_no"
          name="contract_no"
          label="合同编号"
          placeholder="若有合同请填写"
        />
        <van-field readonly
          v-model="form.sample_code"
          name="sample_code"
          label="产品编号"
          placeholder="请选择报检产品"
          required
          clearable
          :rules="[{ required: true, message: '报检产品一定要选呀··' }]"
          @click-input="showProducts">
          <template #button>
            <van-button size="mini" native-type="button" type="default" @click="clearSample">清空</van-button>
          </template>
        </van-field>
        <van-field readonly
          :value="form.supplier_name"
          name="supplier_name"
          label="供应商"
          placeholder="请选择供应商"
          :required="form.inspect_type === '2' || form.inspect_type === '10'"
          :rules="[{ required: form.inspect_type === '2' || form.inspect_type === '10', message: '供应商选择一下呗···' }]"
          @click-input="showSupplier">
          <template #button>
            <van-button size="mini" native-type="button" type="default" @click="clearSupplier">清空</van-button>
          </template>
        </van-field>
        <van-field v-if="form.sample_code"
          :value="form.sample_name + ' ' + form.sample_specs"
          name="sample_name"
          label="名称规格"
          disabled
          @click="showProducts"
        />
        <van-field clearable
          v-model="form.inspect_batch"
          name="inspect_batch"
          label="报检批次"
          placeholder="请输入报检批次"
          :required="form.inspect_type !=='6'"
          :rules="[{ required: form.inspect_type !=='6', message: '报检批次需要填写哦···' }]"
        />
        <van-field clearable
          v-if="form.inspect_type === '2' || form.inspect_type === '8' || form.inspect_type === '9' || form.inspect_type === '10'"
          v-model="form.inspect_num"
          name="inspect_num"
          label="数量"
          placeholder="单位请换算升或者千克"
        />
        <van-field clearable
          v-if="form.inspect_type === '2' || form.inspect_type === '10'"
          v-model="form.erp_purchase_arrival_no"
          name="erp_purchase_arrival_no"
          label="到货单号"
          placeholder="ERP采购到货单号"
        />
        <van-field clearable
          v-if="form.inspect_type === '2' || form.inspect_type === '10'"
          v-model="form.car_number"
          name="car_number"
          label="车牌号码"
          placeholder="请填写来车车牌号"
        />
        <van-field clearable
          v-model="form.other_remark"
          name="other_remark"
          label="其他说明"
          placeholder="其他说明"
        />
        <van-field clearable
          v-if="form.inspect_type === '6' || form.inspect_type === '7'"
          v-model="form.production_line"
          name="production_line"
          label="生产线"
          placeholder="请填写生产线"
          :required="form.inspect_type ==='6' || form.inspect_type ==='7'"
          :rules="[{ required: form.inspect_type ==='6' || form.inspect_type ==='7', message: '生产线必须填写哦···' }]"
        />
        <van-field clearable
          v-if="form.inspect_type === '6' || form.inspect_type === '7'"
          v-model="form.original_product"
          name="original_product"
          label="原产品"
          placeholder="请填写此生产线原来生产的产品"
          :required="form.inspect_type ==='6' || form.inspect_type ==='7'"
          :rules="[{ required: form.inspect_type ==='6' || form.inspect_type ==='7', message: '原产品必须填写哦···' }]"
        />
      </van-cell-group>
      <van-cell-group class="mt10">
        <van-cell title="报检项目" title-style="width:6.2em; flex:none">
          <van-checkbox-group v-model="form.inspect_items" direction="horizontal">
            <van-checkbox icon-size="13px" shape="square" v-for="(itme, idx) in projects" :key="idx" :name="itme.check_item_id">{{itme.check_name}}</van-checkbox>
          </van-checkbox-group>
        </van-cell>
        <van-field
          v-model="form.inspect_remark"
          rows="1"
          autosize
          label="说明"
          type="textarea"
          placeholder="请输入其他重要检测说明"
        />
        <van-cell title="附件上传">
          <van-uploader multiple
            accept="image/*"
            v-model="upload"
            :max-count="5"
            :after-read="afterRead" />
        </van-cell>
      </van-cell-group>
      <van-row gutter="20" class="btgroup" type="flex" justify="center">
        <van-col span="8">
          <van-button round block plain size="small" type="default" native-type="button" @click="toUrl">返回列表</van-button>
        </van-col>
        <van-col span="16">
          <van-button round block 
          size="small" 
          type="primary" 
          native-type="submit" 
          :disabled="submitStatus" 
          v-track="{triggerType:'click', title:'提交报检', currentUrl: $route.path, behavior: this, duration: new Date().getTime()}">提交</van-button>
        </van-col>
      </van-row>
    </van-form>
<!-- 样品类型 弹窗 -->
    <van-popup v-model="typeStatus" position="bottom" round>
      <van-picker title="样品类型"
        show-toolbar
        :columns="types"
        :default-index="typesIdx"
        value-key="type_name"
        @confirm="typeConfirm"
        @cancel="typeStatus = false"
      />
    </van-popup>
<!-- 供应商 弹窗 -->
    <van-popup v-model="supplierStatus" position="bottom" round :style="{ height: '90%' }">
      <van-search v-model="supplierParams.supplier_name" placeholder="请输入供应商关键词" @input="onSearch('supplier')"/>
      <van-index-bar v-if="suppliers.length > 0">
        <van-cell v-for="(item, idx) in suppliers" :key="idx" :title="item.supplier_name" @click="toSupplier(idx)"/>
      </van-index-bar>
      <div v-else><p class="tc">没有数据记录，请使用关键词搜索</p></div>
    </van-popup>
<!-- 报检产品 弹窗 -->
    <van-popup v-model="productStatus" position="bottom" round :style="{ height: '90%' }">
      <van-search v-model="productParams.sample_name" placeholder="请输入报检产品关键词" @input="onSearch('product')"/>
      <van-index-bar v-if="products.length > 0">
        <van-cell v-for="(item, idx) in products"
          :key="idx" 
          :title="item.sample_name + ' ' + item.sample_specs + ' [' + item.sample_erp_code + ']'" 
          :label="item.supplier_name"
          @click="toProduct(idx)"/>
      </van-index-bar>
      <div v-else><p class="tc">没有数据记录，请使用关键词搜索</p></div>
    </van-popup>
<!-- 批号选择，日期 弹窗 -->
    <van-popup round v-model="dateShow" position="bottom">
      <van-datetime-picker type="date"
          v-model="currentDate"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="DateConfirm"
          @cancel="dateShow = false"
        />
    </van-popup>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import { Form, Field, CellGroup, Picker, Popup, IndexBar, IndexAnchor, Checkbox, CheckboxGroup, Uploader, Button, Cell, Search, Row, Col, NavBar, DatetimePicker } from 'vant'
import { ossUpload } from '@/common/fileUpload'
Vue.use(Form)
Vue.use(Field)
Vue.use(CellGroup)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(IndexBar)
Vue.use(IndexAnchor)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Uploader)
Vue.use(Button)
Vue.use(Cell)
Vue.use(Search)
Vue.use(Row)
Vue.use(Col)
Vue.use(NavBar)
Vue.use(DatetimePicker)

export default {
  data() {
    return {
      id: '',
      type: '',
      typeStatus: false,
      supplierStatus: false,
      productStatus: false,
      dateShow: false,
      currentDate: new Date(),
      minDate: new Date(2018, 0, 1),
      maxDate: new Date(),
      form: {
        id: '',
        type:'',
        inspect_type: '',
        attach_info:'',
        supplier_name: '',
        supplier_code: '',
        sample_code: '',
        sample_name: '',
        sample_specs: '',
        inspect_batch: ''
      },
      tempForm: {},
      types: [],
      suppliers: [],
      products: [],
      user: {},
      submitStatus: false,
      supplierName: '',
      typesIdx: 2,
      supplierParams: {
        page: 1,
        offset: 12,
        supplier_name: ''
      },
      productParams: {
        page: 1,
        offset: 12,
        supplier_code: '',
        supplier_name: '',
        sample_code:'',
        sample_name: ''
      },
      projects: [],
      upload: [],
      attach_info: []
    }
  },
  async created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      await this.getDetail()
      this.tempForm = Object.assign({}, this.form)
    }
    await this.init()
    // const user = JSON.parse(localStorage.getItem('user'))
  },
  mounted() { },
  computed:{ },
  methods: {
    ...mapActions('listData', ['asyncsetSampleType']),
    ...mapActions('router', ['asyncUser']),
    async init() {
      await this.showType()
      const user = JSON.parse(localStorage.getItem('user'))
      return new Promise((resolve) => {
        this.types.forEach((res, idx) => {
          if (res.remark === user.role) {
            this.typesIdx = idx
            this.$set(this.form, 'type', res.type_name)
            this.$set(this.form, 'inspect_type', res.id)
          }
        })
        this.$set(this.form, 'inspect_emp_id', user.dd_data.dd_user_id)
        this.$set(this.form, 'inspect_emp_name', user.dd_data.dd_user_name)
        this.$set(this.form, 'inspect_dep_id', user.dd_data.dd_dep_id)
        this.$set(this.form, 'inspect_dep_name', user.dd_data.dd_dep_name)
        resolve(this.form)
      })
    },
    onSubmit() {
      const isEqual = this.$common.isObjEqual(this.form, this.tempForm, 2)
      if (isEqual) {
        this.$notify({ type: 'warning', message: '您还没有修改任何信息！' })
        return false
      }
      this.form.attach_info = this.attach_info
      let url = 'inspect/add-inspect'
      if (this.type === '0') {
        this.form.id = this.id
        url = 'inspect/save-inspect'
      } else {
        this.form.id = ''
      }
      this.submitStatus = true
      this.$post(url, 'api', this.form).then(res => {
        this.submitStatus = false
        this.$notify({ type: 'success', message: res.message })
        setTimeout(() => {
          this.$router.push({ path: '/samplelist' })
        }, 2000)
      }, err => {
        this.submitStatus = false
        this.$notify({ type: 'warning', message: err.message })
      }).catch(err => {
        this.submitStatus = false
        this.$notify({ type: 'danger', message: err.message })
      })
    },
    async getDetail() {
      return new Promise((resolve, reject) => {
        this.$post('inspect/get-inspect-detail', 'api', {
          id: this.id
        }).then(res => {
          const Detail = res.data
          for (let item in Detail) {
            if (item.includes('time')) {
              if (Detail[item] !== '0') {
                Detail[item] = '0'
              }
            }
          }
          Detail.inspect_status = '0'
          Detail.type = Detail.type.type_name
          this.form = {
            ...Detail
          }
          this.getProjects(Detail.sample_code, Detail.sample_name, Detail.sample_specs)
          this.upload = Detail.attach_id.map(res => {
            return {
              url: res
            }
          })
          resolve(Detail)
        }, err => {
          this.$notify({ type: 'warning', message: err.message })
          reject(err)
        })
      })
    },
    async showType() {
      return new Promise((resolve, reject) => {
        const that = this
        const SampleType = this.$store.state.listData.SampleType
        if (SampleType.length === 0) {
          that.$get('sample-type/get-sample-type-list', 'api', {
            page: 1,
            offset: 100,
          }).then(res => {
            const datas = res.data.list
            that.types = datas
            that.asyncsetSampleType(datas)
            resolve(datas)
          }, err => {
            this.$notify({ type: 'warning', message: err.message })
            reject(err)
          })
        } else {
          that.types = SampleType
          resolve(SampleType)
        }
        // this.typeStatus = true
       }).catch(err => {
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    onSearch(type) {
      if (type === 'supplier') {
        if (this.supplierParams.supplier_name) {
          this.$get('inspect/get-supplier-list', 'api', this.supplierParams).then(res => {
            this.suppliers = res.data.list
          }, err => {
            this.suppliers = []
            this.$notify({ type: 'warning', message: err.message })
          })
        } else {
          this.$notify({ type: 'warning', message: '请输入搜索关键词' })
        }
      } else if (type === 'product') {
        if (this.productParams.sample_name) {
          this.$get('inspect/get-sample-list', 'api', this.productParams).then(res => {
            this.products = res.data.list
          }, err => {
            this.products = []
            this.$notify({ type: 'warning', message: err.message })
          })
        } else {
          this.$notify({ type: 'warning', message: '请输入搜索关键词' })
        }
      }
      
    },
    typeConfirm(val) {
      this.form.type = val.type_name
      this.form.inspect_type = val.id
      this.typeStatus = false
    },
    toSupplier(idx) {
      Object.assign(this.form, this.suppliers[idx])
      this.supplierStatus = false
    },
    clearSupplier() {
      this.form.supplier_name = ''
      this.form.supplier_code = ''
    },
    toProduct(idx) {
      Object.assign(this.form, this.products[idx])
      const pro = this.products[idx]
      this.getProjects(pro.sample_code, pro.sample_name, pro.sample_specs)
      this.productStatus = false
    },
    clearSample() {
      this.form.sample_code = ''
      this.form.sample_name = ''
      this.form.sample_specs = ''
      this.projects = []
    },
    DateConfirm(date) {
      this.dateShow = false
      this.form.inspect_batch = this.$common.formatDate(date, '')
    },
    getProjects(code, name, specs) {
      this.$get('inspect/get-sample-check-standard', 'api', {
        sample_code: code,
        sample_name: name,
        sample_specs: specs
      }).then(res => {
        this.projects = res.data.sample_check_standard.filter(ref => {
          return ref.check_item_id && ref.check_standard
        })
      }, err => {
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 返回布尔值
    // beforeRead(file) {
    //   if (file.type !== 'image/jpeg') {
    //     this.$notify({ type: 'warning', message: '请上传 jpg 格式图片' })
    //     return false
    //   }
    //   return true
    // },
    showSupplier() {
      this.supplierStatus = true
      this.$set(this.supplierParams, 'supplier_name', '')
      this.getSuppliersTop()
    },
    showProducts() {
      this.productStatus = true
      if (this.form.supplier_name) {
        this.onSearch('product')
      } else {
        this.getProductsTop()
      }
    },
    getSuppliersTop() {
      this.$get('inspect/get-used-supplier-list', 'api', {
        inspect_type: this.form.inspect_type
      }).then(res => {
        this.suppliers = res.data.list
      }, err => {
        console.log(err)
        this.suppliers = []
      })
    },
    getProductsTop() {
      this.$get('inspect/get-used-sample-list', 'api', {
        inspect_type: this.form.inspect_type,
        supplier_name: this.productParams.supplier_name
      }).then(res => {
        this.products = res.data.list
      }, err => {
        console.log(err)
        this.products = []
      })
    },
    afterRead(upload) {
      upload.status = 'uploading'
      upload.message = '上传中...'
      this.submitStatus = true
      ossUpload(upload).then((res) => {
        if (res.url) {
          this.attach_info.push({
            attach_name: res.name,
            attach_type: res.type,
            attach_path: res.name,
            attach_url: res.url
          })
          upload.status = 'done'
          upload.message = '上传成功'
        } else {
          upload.status = 'failed'
          upload.message = '上传失败'
        }
        this.submitStatus = false
      }).catch(err => {
        console.log(err)
        this.submitStatus = false
      })
    },
    onOversize(file) {
      console.log(file)
      this.$notify({ type: 'warning', message: '文件大小不能超过 2M' })
    },
    back() {
      this.$router.push({ path: '/home' })
      // this.$router.go(-1)
    },
    toUrl() {
      this.$router.push({ path: '/samplelist' })
    }
  }
}
</script>

<style>
.btgroup{
  margin: 10px 12px 40px;
}
</style>
